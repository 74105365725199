.webcrossing-product-gallery-container.mode-masonry {
  .grid-item {
    display:block;
    margin-bottom:$gutter;

    &.full { width:100%; }
    &.half { width:calc(((100% - (#{$gutter} * 1)) / 2) * 1); }
    &.one-third { width:calc(((100% - (#{$gutter} * 2)) / 3) * 1); }
    &.one-fourth { width:calc(((100% - (#{$gutter} * 3)) / 4) * 1); }

    img {
      display:block;
      width:100%;
    }
  }
}

@media only screen and (max-width: #{$mediumBreakpoint}) {
  .webcrossing-product-gallery-container.mode-masonry {
    .grid-item {
      &.one-third { width: calc(((100% - (#{$gutter} * 1)) / 2) * 1); }
      &.one-fourth { width: calc(((100% - (#{$gutter} * 1)) / 2) * 1); }
    }
  }
}

@media only screen and (max-width: #{$mobileBreakpoint}) {
  .webcrossing-product-gallery-container.mode-masonry {
    .grid-item {
      &.half { width:100%; }
      &.one-third { width:100%; }
      &.one-fourth { width:100%; }
    }
  }
}