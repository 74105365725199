.webcrossing-product-gallery-container.mode-static {
  .grid-item {
    display:block;
    margin-bottom:$gutter;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.ratio-1-1 { padding-top: 100%; }
    &.ratio-4-3 { padding-top: 75%; }
    &.ratio-16-9 { padding-top: 56.25%; }
    &.ratio-21-9 { padding-top: 42.85%; }
    &.ratio-32-9 { padding-top: 28.12%; }
    &.ratio-43-9 { padding-top: 20.93%; }

    &.full { width:100%; }
    &.half { width:calc(((100% - (#{$gutter} * 1)) / 2) * 1); }
    &.one-third { width:calc(((100% - (#{$gutter} * 2)) / 3) * 1); }
    &.two-thirds { width:calc((((100% - (#{$gutter} * 2)) / 3) * 2) + (#{$gutter} * 1)); }
    &.one-fourth { width:calc(((100% - (#{$gutter} * 3)) / 4) * 1); }
    &.two-fourths { width:calc((((100% - (#{$gutter} * 3)) / 4) * 2) + (#{$gutter} * 1)); }
    &.three-fourth { width:calc((((100% - (#{$gutter} * 3)) / 4) * 3) + (#{$gutter} * 2)); }
  }
}

@media only screen and (max-width: #{$mediumBreakpoint}) {
  .webcrossing-product-gallery-container.mode-static {
    .grid-item {
      &.ratio-43-9 { padding-top: 28.12%; }
    }
  }
}

@media only screen and (max-width: #{$mobileBreakpoint}) {
  .webcrossing-product-gallery-container.mode-static {
    .grid-item {
      &.ratio-32-9 { padding-top: 42.85%; }
      &.ratio-43-9 { padding-top: 42.85%; }

      &.half { width:100%; }
      &.one-third { width:100%; }
      &.two-thirds { width:100%; }
      &.one-fourth { width:100%; }
      &.two-fourths { width:100%; }
      &.three-fourth { width:100%; }
    }
  }
}